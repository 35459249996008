.menuItem {
    border-bottom: 1px solid #0369a1;
    text-align: left;
    cursor: pointer;
  }
  
  .menuItem > h4 {
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-bottom: 15px;
    font-size: 15px;
    padding-left: 10px;
    cursor: pointer;
    color:#0369a1;
    text-decoration: none;
    

  }
  .menuItem > h4:hover {
    color: #0ea5e9 !important;
  }
  