.teslaAccount__header {
    padding: 7px 20px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0369a1;
    color: white;
  }
  
  .teslaAccount__links {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .teslaAccount__links > a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
    z-index: 0;
  }
  
  .teslaAccount__logo > a > img {
    object-fit: contain;
    width: 150px;
    /* filter: brightness(0) invert(1); */
  }
  
  .teslaAccount__menu {
    z-index: 2;
  }
  
  .teslaAccount__menu > .MuiSvgIcon-root {
    cursor: pointer;
  }
  
  .teslaAccount__closeMenu {
    color: #0369a1;
  }
  
  .teslaAccount__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 140px;
    padding-bottom: 30px;
  }
  
  .teslaAccount__person > h4 {
    font-weight: 500;
    /* text-transform: capitalize; */
    font-size: x-large;
  }
  
  .teslaAccount__infoRight {
    display: flex;
    gap: 20px;
  }
  
  .teslaAccount__infoRight > a {
    text-decoration: none;
    color: #0369a1;
    font-size: smaller;
    transition: all 0.2s;
    text-transform: capitalize;
  }
  
  .teslaAccount__infoRight > a:hover {
    color: #0ea5e9;
    font-weight: 500;
  }
  
  .teslaAccount__car {
    display: grid;
    place-items: center;
    gap: 30px;
    padding-bottom: 40px;
  }