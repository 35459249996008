.buttonPrimary {
    background-color: #ff5316;
    border: none;
    padding: 12px 40px;
    border-radius: 50px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    margin-bottom: 30px;
    transition: all 0.4s;
  }
  
  .buttonPrimary:hover {
    background-color: #f8931f;
  }
  