.menu {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    right: 0;
    padding-left: 20px;
    padding-top: 70px;
    padding-bottom: 20px;
    padding-right: 20px;
    background-color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: 280px;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .menu::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .menu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  