.headerBlock {
    background-color: black;
      /* background: url('https://master-auto-tech.com/wp-content/uploads/2021/11/logo-2400x2261-3.jpg') no-repeat
        center center fixed; */
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size:contain;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: grid;
      place-items: center;
    }
    
    .headerBlock__info {
      display: flex;
      flex-direction: column;
      height: 360px;
    }
    
    .headerBlock__infoText {
      text-align: center;
      flex: 1;
    }
    
    .headerBlock__infoText > h1 {
      color: #0369a1;
      font-weight: 500;
      font-size: 38px;
    }
    
    .headerBlock__infoText > h4 {
      font-weight: 400;
      font-size: 15px;
      color: #5c5e62;
    }
    
    .headerBlock__infoText > h4 > span {
      padding-bottom: 4px;
      padding-top: 15px;
      border-bottom: 1px solid black;
      color: #393c41;
      cursor: pointer;
    }
    
    .headerBlock__infoText > h4 > span:hover {
      border-bottom-width: 2px;
      color: black;
    }
    
    .headerBlock__actions {
      margin-top: 330px;
      display: flex;
      gap: 20px;
    }
    
    .headerBlock__actions > button {
      cursor: pointer;
      border: none;
      width: 260px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 50px;
      text-transform: uppercase;
      font-weight: 600;
      outline: none;
    }
    
    .headerBlock__buttonPrimary {
      background-color: #0369a1;
      color: white;
      opacity: 0.9;
    }
    
    .headerBlock__buttonSecondary {
      background-color: white;
      color: #0369a1;
      opacity: 0.8;
    }
    



    header {
      position: relative;
      background-color: black;
      height: 75vh;
      min-height: 25rem;
      width: 100%;
      overflow: hidden;
    }
    
    header video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
    
    header .container {
      position: relative;
      z-index: 2;
    }
    
    header .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.5;
      z-index: 1;
    }
    
    /* Media Query for devices withi coarse pointers and no hover functionality */
    
    /* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */
    
    @media (pointer: coarse) and (hover: none) {
      header {
        background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
      }
    
      header video {
        display: none;
      }
    }