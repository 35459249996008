table {
    border-collapse: collapse;

}
thead {
    border: 2px solid black;
}
tbody {
    border: 2px solid black;
}
td {
    border: 2px solid black;
}
th {
    border: 2px solid black;
}
