.login {
    padding: 25px;
    padding-top: 15px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 70px;
  }
  
  .login__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
  }
  
  .login__logo > a > img {
    object-fit: contain;
    width: 240px;
  }
  
  .login__language {
    display: flex;
    gap: 5px;
    align-items: flex-end;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 15px;
    transition: all 0.2s;
  }
  
  .login__language:hover {
    background-color: hsla(0, 0%, 50.2%, 0.125);
  }
  
  .login__language > span {
    font-weight: 500;
  }
  
  .login__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .login__info > h1 {
    font-weight: 500;
    font-size: 40px;
    margin-left: -10px;
  }
  
  .login__form {
    display: flex;
    flex-direction: column;
  }
  
  .login__form > label {
    color: #5c5e62;
    font-weight: 500;
    font-size: 15px;
    padding-left: 20px;
  }
  
  .login__form > input {
    margin-bottom: 30px;
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;
    outline: none;
    border-radius: 50px;
    padding: 12px 20px;
    color: #393c41;
    font-weight: 600;
  }
  
  .login__form > input:focus {
    border: 1px solid #d6d6d7;
    transition: all 0.2s;
  }
  
  .login__form > button {
    background-color: #0369a1;
    border: none;
    padding: 12px 40px;
    border-radius: 50px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    margin-bottom: 30px;
    transition: all 0.4s;
  }
  
  .login__form > button:hover {
    background-color: #0ea5e9;
  }
  
  .login__divider {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .login__divider > hr {
    width: 40%;
    height: 0;
    opacity: 0.3;
  }
  
  .login__divider > span {
    font-weight: 500;
    color: #5c5e62;
  }
  
  .login__info > a > button {
    background-color: transparent;
    border: 3px solid #171a20;
    width: 100%;
    padding: 10px 40px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #171a20;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;
  }
  
  .login__info > a > button:hover {
    background-color: #171a20;
    color: white;
  }